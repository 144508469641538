export default {
  async insert (Vue, formData, idtecnico, idparteTrabajo, ffin) {
    await Vue.$offline.tiempoTrabajado.insertarTiempoTrabajadoParte(
      formData.kilometros,
      formData.observaciones,
      formData.idttarea,
      formData.manodeobrafacturable,
      formData.kilometros_facturables,
      formData.es_desplazamiento,
      formData.para,
      idtecnico,
      formData.finicio,
      ffin,
      formData.latitud,
      formData.longitud,
      formData.latitud,
      formData.longitud,
      idparteTrabajo,
      formData.idsubsis,
      false,
      formData.idvehiculo,
    )
  },
}
